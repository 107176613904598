// Variable overrides
// from path: /node_modules/@coreui/coreui/scss/_variables.scss

@import url("https://fonts.googleapis.com/css2?family=Roboto");

* {
  font-family: "Roboto", sans-serif;
}

$white: #fff;
$gray-base: #181b1e;
$gray-100: #f0f3f5; // lighten($gray-base, 85%);
$gray-200: #e4e7ea; // lighten($gray-base, 80%);
$gray-300: #c8ced3; // lighten($gray-base, 70%);
$gray-400: #acb4bc; // lighten($gray-base, 60%);
$gray-500: #8f9ba6; // lighten($gray-base, 50%);
$gray-600: #73818f; // lighten($gray-base, 40%);
$gray-700: #5c6873; // lighten($gray-base, 30%);
$gray-800: #2f353a; // lighten($gray-base, 10%);
$gray-900: #23282c; // lighten($gray-base, 5%);
$black: #000;

// $blue: #20a8d8;
// overwritten:
$blue: #434d58; // =$nextdriver-darkblue
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #f86c6b;
$orange: #f8cb00;
// $yellow: #ffc107;
// overwritten:
$yellow: #f68e4f;
$green: #45a567;
$teal: #20c997;
$cyan: #17a2b8;
// $light-blue: #63c2de;
// overwritten:
$light-blue: #20b3dd;

$primary: $blue;
$secondary: $gray-300;
$success: $green;
$info: $light-blue;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

$nextdriver-grey: $gray-300;
$imessage-grey: #e5e5ea;
$nextdriver-darkblue: #434d58;

// SIDEBAR NAVIGATION STYLE
$sidebar-width: 300px;
$sidebar-bg: $blue; //=$nextdriver-darkblue  //before: $gray-700;

$sidebar-nav-title-color: $gray-100;

$sidebar-nav-link-padding-x: 0.5rem;

$sidebar-nav-dropdown-bg: $gray-600;

$sidebar-nav-dropdown-indicator-color: white;
// overwriting sidebar dropdown arrow's orientation and movement
.sidebar .nav-dropdown-toggle::before {
  transform: rotate(180deg);
  right: 1rem !important;
}
.sidebar .nav-dropdown.open > .nav-dropdown-toggle::before {
  transform: rotate(270deg) !important;
}

$sidebar-nav-link-icon-color: white;

$sidebar-nav-link-hover-icon-color: $gray-800;
$sidebar-nav-link-hover-color: $gray-800 !important;
$sidebar-nav-link-hover-bg: lighter !important;
$sidebar-nav-link-active-color: $gray-800 !important;
$sidebar-nav-link-active-icon-color: $gray-800;
$sidebar-nav-link-active-bg: $gray-200 !important;
$sidebar-nav-link-active-borders: (
  bottom: (
    size: 1px,
    style: solid,
    color: $gray-800,
  ),
  top: (
    size: 1px,
    style: solid,
    color: $gray-800,
  ),
);
// on hover animations
.sidebar .nav-link {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.sidebar .nav-link .badge {
  transition: none;
}

.sidebar .nav-link .nav-icon {
  width: 1.5rem !important;
}

// selecting .sidebar class's child class named .nav-dropdown.open class's all children that has the classname .nav-dropdown-items
.sidebar .nav-dropdown.open > .nav-dropdown-items {
  max-height: fit-content !important;
}

// TABLE STYLE
$table-accent-bg: $nextdriver-grey;
